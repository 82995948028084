exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@charset \"UTF-8\";\n/* 改变主题色变量 */\n#app .el-button {\n  font-weight: 400;\n  border-radius: 4px;\n}", ""]);

// exports
exports.locals = {
	"colorPrimary": "#1890ff"
};